import { graphql } from "gatsby"
import React from "react"
import { withPreview } from "gatsby-source-prismic"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import UnderlinedTitle from "../../components/UnderlinedTitle"
import FAQ from "../../components/FAQ"
import MediumWidget from "../../components/MediumWidget"
import FullWidthImage from "../../components/FullWidthImage"
import Partners from "../../components/Partners"
import RichText from "../../components/RichText/richtext"
import EmbeddedContent from "../../components/EmbeddedContent"
import About from "../../components/About"
import SocialButtons from "../../components/SocialButtons"
import Hero from "../../components/Hero"
import InstagramFeed from "../../components/InstagramFeed"
import MusicMediaEmbed from "../../components/MusicMediaEmbed"
import MusicMediaBlock from "../../components/MusicMediaBlock"
import Button from "../../components/Button"
import EmbedCarousel from "../../components/EmbedCarousel"
import Title from "../../components/Title"
import ApplePodcastEmbed from "../../components/ApplePodcastEmbed"
import SDGVisualization from "../../components/SDGVisualization"
import styles from "../../scss/sdg.module.scss"

const SDGPage = ({ data }) => {
  const sliceComponents = {
    PrismicSdgResearchBodyUnderlinedTitle: UnderlinedTitle,
    PrismicSdgResearchBodyFaq: FAQ,
    PrismicSdgResearchBodyMediumWidget: MediumWidget,
    PrismicSdgResearchBodyFullWidthImage: FullWidthImage,
    PrismicSdgResearchBodyPartnersAirtable: Partners,
    PrismicSdgResearchBodyJuicerFeed: InstagramFeed,
    PrismicSdgResearchBodyRichtext: RichText,
    PrismicSdgResearchBodyEmbeddedContent: EmbeddedContent,
    PrismicSdgResearchBodyAbout: About,
    PrismicSdgResearchBodySocialButtons: SocialButtons,
    PrismicSdgResearchBodyHero: Hero,
    PrismicSdgResearchBodyMediaMusicEmbed: MusicMediaEmbed,
    PrismicSdgResearchBodyMediaMusicBlock: MusicMediaBlock,
    PrismicSdgResearchBodyButton: Button,
    PrismicSdgResearchBodyVideoCarousel: EmbedCarousel,
    PrismicSdgResearchBodyTitle: Title,
    PrismicSdgResearchBodyApplePodcastsEmbed: ApplePodcastEmbed,
    PrismicSdgResearchBodySdgVisualization: SDGVisualization,
  }
  console.log(data)
  const pageData = data.allPrismicSdgResearch.nodes[0].data
  return (
    <Layout>
      <SEO
        title={pageData.title.text}
        description={pageData.meta_description.text}
        image={pageData.meta_image.url}
      />
      <div className={styles.sdgPageContainer}>
        {pageData.body.map(({ __typename, ...props }, i) => {
          if (sliceComponents[__typename])
            return React.createElement(sliceComponents[__typename], {
              ...props,
              // eslint-disable-next-line react/no-array-index-key
              key: `${__typename}-${i}`,
            })
          return <p key={__typename}>TODO: {__typename}</p>
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicSdgResearch {
      nodes {
        data {
          meta_description {
            text
          }
          meta_image {
            url
          }
          title {
            text
          }
          body {
            ... on PrismicSdgResearchBodyHero {
              primary {
                above_title {
                  text
                }
                above_subtitle {
                  html
                }
                logo {
                  fluid(maxWidth: 300) {
                    ...GatsbyPrismicImageFluid
                  }
                  alt
                }
                logo_label
                below_title {
                  text
                }
                below_subtitle {
                  text
                }
                size
                subtitle_size
              }
              items {
                image {
                  fluid(maxWidth: 2000) {
                    ...GatsbyPrismicImageFluid
                  }
                  alt
                }
                image_label
              }
            }
            ... on PrismicSdgResearchBodyFullWidthImage {
              primary {
                image {
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
                label {
                  text
                }
              }
            }
            ... on PrismicSdgResearchBodyFaq {
              primary {
                toc_title {
                  text
                }
              }
              items {
                answer {
                  html
                }
                question {
                  text
                }
                group_name
              }
            }
            ... on PrismicSdgResearchBodyUnderlinedTitle {
              primary {
                text {
                  text
                }
                bold
              }
            }
            ... on PrismicSdgResearchBodySocialButtons {
              id
              primary {
                above {
                  html
                }
                below {
                  html
                }
              }
              items {
                color
                link {
                  url
                  target
                }
                logo
                logo_label
              }
            }
            ... on PrismicSdgResearchBodyEmbeddedContent {
              primary {
                embed {
                  embed_url
                  height
                  html
                  width
                  url
                  title
                }
                title {
                  text
                }
              }
            }
            ... on PrismicSdgResearchBodyMediumWidget {
              primary {
                author
                claps
                use_description
                fans
                image_size
                limit
                medium_account_link {
                  url
                }
                medium_title {
                  text
                }
                posts_per_line
                publication_date
              }
            }
            ... on PrismicSdgResearchBodyJuicerFeed {
              primary {
                heading {
                  text
                }
              }
            }
            ... on PrismicSdgResearchBodyRichtext {
              primary {
                richtext {
                  html
                }
              }
            }
            ... on PrismicSdgResearchBodyPartnersAirtable {
              primary {
                cscnc_heading {
                  text
                }
                cscnc_description {
                  html
                }
                historical_partners_heading {
                  text
                }
              }
            }
            ... on PrismicSdgResearchBodyAbout {
              id
              primary {
                description {
                  html
                }
                title {
                  text
                }
              }
            }
            ... on PrismicSdgResearchBodyMediaMusicEmbed {
              primary {
                content_embed {
                  html
                  description
                }
              }
            }
            ... on PrismicSdgResearchBodyMediaMusicBlock {
              primary {
                block_embed {
                  html
                  description
                }
                optional_image {
                  fluid(maxWidth: 600) {
                    ...GatsbyPrismicImageFluid
                  }
                  alt
                }
                embed_location
                block_content {
                  html
                }
                optional_image_link {
                  url
                }
              }
            }
            ... on PrismicSdgResearchBodyButton {
              primary {
                margin_top
                margin_bottom
                button_css_classes
                button_label {
                  text
                }
                button_link {
                  url
                }
              }
            }
            ... on PrismicSdgResearchBodyVideoCarousel {
              items {
                embed_label
                description {
                  html
                }
                carousel_embed {
                  html
                  title
                }
              }
            }
            ... on PrismicSdgResearchBodyTitle {
              primary {
                text {
                  text
                }
                bold
              }
            }
            ... on PrismicSdgResearchBodyApplePodcastsEmbed {
              primary {
                podcast_title {
                  text
                }
                embed_link {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview(SDGPage);
