import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import styles from "./sdgvisualization.module.scss"

const SDGVisualization = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      async function load_scripts(script_urls) {
        function load(script_url) {
          return new Promise(function (resolve, reject) {
            if (load_scripts.loaded.has(script_url)) {
              resolve()
            } else {
              var script = document.createElement("script")
              script.onload = resolve
              script.src = script_url
              document.body.appendChild(script)
            }
          })
        }
        var promises = []
        for (const script_url of script_urls) {
          promises.push(load(script_url))
        }
        await Promise.all(promises)
        for (const script_url of script_urls) {
          load_scripts.loaded.add(script_url)
        }
      }
      load_scripts.loaded = new Set()
      ;(async () => {
        await load_scripts([
          "https://d3js.org/d3.v6.min.js",
          "https://unpkg.com/@popperjs/core@2",
          "https://unpkg.com/tippy.js@6",
          "sdg-vis-lang.js",
          "sdg-vis-data-processing.js",
          "sdg-vis-select.js",
          "sdg-vis-stat.js",
          "sdg-vis-chart.js",
          "sdg-vis.js",
        ])

        // Now do stuff with those scripts.
        const script = document.createElement("script")
        script.innerHTML = `
          sdvVis({
            id: "sdg-vis",
            dataURL: "Applications-By SDG-ID.csv",
            lang: '${process.env.GATSBY_BUILD_LANG}', // en or fr
          });
        `
        document.body.appendChild(script)
      })()
    }
  })

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Oswald:wght@500;600&family=Roboto&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/tippy.js@6/themes/translucent.css"
        />
        <link rel="stylesheet" href="style.css" />
      </Helmet>
      <div className={styles.sdgContainer}>
        <div id="sdg-vis"></div>
      </div>
    </>
  )
}

export default SDGVisualization;
